<template>
  <!-- 操作页面 -->
  <div class="padding24 color_white" id="journal_manage">
    <div v-show="$route.meta.isShow">
      <!-- 顶部 -->
      <div class="top">
        <div class="top_left"></div>
        <div class="top_rigiht">
          <span>创建日期 :<a-range-picker @change="onChangeDate" /> </span>
          <a-input
            v-model="model"
            placeholder="请输入模块"
            @pressEnter="searchClick"
          >
            <a-icon slot="prefix" type="search" />
          </a-input>
          <a-input
            v-model="describe"
            placeholder="请输入操作名"
            @pressEnter="searchClick"
          >
            <a-icon slot="prefix" type="search" />
          </a-input>
          <a-input
            v-model="userName"
            placeholder="请输入账号"
            @pressEnter="searchClick"
          >
            <a-icon slot="prefix" type="search" />
          </a-input>
          <a-input
            v-model="parameter"
            placeholder="请输入请求参数"
            @pressEnter="searchClick"
          >
            <a-icon slot="prefix" type="search" />
          </a-input>
          <a-button
            icon="search"
            type="primary"
            @click="searchClick"
            :disabled="isDisableSearch"
            >搜索</a-button
          >
        </div>
      </div>
      <a-table
        :columns="columns"
        :data-source="tabledData"
        :pagination="false"
        :loading="tableLoading"
      >
        <span slot="operation" slot-scope="operation, record">
          <a @click="detailClick(record.id)">详情</a>
        </span>
      </a-table>
      <!-- 分页功能 -->
      <MyPagination
        :count="count"
        :pageNo="pageNo"
        @showSizeChangeFn="showSizeChangeFn"
        v-show="!tableLoading"
      />
      <!-- 返回顶部 -->
      <a-back-top :target="targetFn" :visibilityHeight="100" />
    </div>
    <router-view />
  </div>
</template>

<script>
import { GetOperationsApi } from "@/request/api/systemManage";
import MyPagination from "@/components/pagination/MyPagination";

export default {
  components: { MyPagination },
  created() {
    this.GetOperationsFn();
  },
  data() {
    return {
      userName: "", // 账号名
      describe: "", // 操作
      model: "", // 模块
      parameter: "", // 请求参数
      startTime: "",
      endTime: "",
      pageNo: 1,
      pageSize: 20,
      count: 0, // 列表总条数
      tableLoading: true, // 表格的loading图
      isDisableSearch: false, // 搜索按钮是否禁用
      columns: [
        {
          title: "操作人",
          dataIndex: "operator",
          key: "operator",
          width: "10%",
        },
        {
          title: "操作描述",
          dataIndex: "describe",
          width: "8%",
          key: "describe",
        },
        {
          title: "操作模块",
          dataIndex: "model",
          width: "6%",
          key: "model",
        },
        {
          title: "请求方法",
          dataIndex: "methodName",
          key: "methodName",
          width: "20%",
        },
        {
          title: "请求参数",
          dataIndex: "parameter",
          key: "parameter",
          ellipsis: true,
          width: "30%",
        },
        {
          title: "操作时间",
          dataIndex: "createTime",
          width: "10%",
          sorter: true,
          key: "createTime",
          sorter: (a, b) => {
            let aTime = new Date(a.createTime).getTime();
            let bTime = new Date(b.createTime).getTime();
            return aTime - bTime;
          },
        },
        {
          title: "操作",
          key: "operation",
          dataIndex: "operation",
          width: "4%",
          scopedSlots: { customRender: "operation" },
        },
      ],
      tabledData: [],
    };
  },
  methods: {
    // 创建日期改变
    onChangeDate(date, dateString) {
      if (dateString[0] != "") {
        this.startTime = dateString[0] + " 00:00:00";
        this.endTime = dateString[1] + " 23:59:59";
      } else {
        this.startTime = "";
        this.endTime = "";
      }
    },
    // 搜索按钮 , 回车搜索
    searchClick() {
      this.pageNo = 1;
      this.isDisableSearch = true;
      setTimeout(() => {
        this.isDisableSearch = false;
      }, 3000);
      this.GetOperationsFn();
    },
    // 分页功能切换的回调
    showSizeChangeFn(current, pageSize) {
      this.pageNo = current;
      this.pageSize = pageSize;
      this.GetOperationsFn();
    },
    // 详情的按钮
    detailClick(id) {
      this.$router.push(
        `/systemManage/systemAbnormal/journalManageDetail/${id}`
      );
    },
    //  获取操作日志列表函数
    GetOperationsFn() {
      GetOperationsApi({
        pageNo: this.pageNo,
        pageSize: this.pageSize,
        userName: this.userName, // 账号
        describe: this.describe, // 操作
        model: this.model, // 模块
        parameter: this.parameter, // 请求参数
        startTime: this.startTime,
        endTime: this.endTime,
      }).then(({ code, data }) => {
        if (code == 200) {
          this.tableLoading = false;
          let newTableData = data.data;
          for (let i in newTableData) {
            newTableData[i].key = i;
            newTableData[i].operator =
              newTableData[i].operator == "" ? "——" : newTableData[i].operator;
          }
          this.tabledData = newTableData;
          this.count = data.count;
        }
      });
    },
    // 回到顶部的函数
    targetFn() {
      return document.querySelector("#journal_manage");
    },
  },
};
</script>
 
<style lang="less" scoped>
#journal_manage {
  height: 100%;
  overflow: hidden;
  overflow-y: scroll;
}
.top {
  display: flex;
  justify-content: space-between;
  .top_rigiht {
    .ant-btn {
      margin-left: 10px;
    }
    /deep/.ant-input-affix-wrapper {
      margin-left: 10px;
      width: 200px;
    }
  }
}
/deep/ .ant-table-wrapper {
  margin-top: 20px;
}
/deep/.ant-table-thead > tr > th {
  font-weight: 600;
}
</style>